import React from 'react';
import { string } from 'prop-types';
import classnames from 'classnames';

const ICON_ID = 'points_of_interest_walking';
const namespace = 'ui-pdp-icon ui-pdp-icon--poi-walking';

const IconWalking = ({ className }) => (
  <svg
    className={classnames(namespace, className)}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    viewBox="0 0 10 16"
  >
    <use href={`#${ICON_ID}`} />
  </svg>
);

IconWalking.propTypes = {
  className: string,
};

IconWalking.defaultProps = {
  className: null,
};

IconWalking.ICON_ID = ICON_ID;

export default React.memo(IconWalking);
export { IconWalking };
